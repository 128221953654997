.login {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.login__title {
  text-align: center;
  font-size: 64px;
  font-family: monospace;
  font-weight: bold;
}

.login > button {
  width: 300px;
  height: 40px;
  background-color: #1982FC;
  color: white;
  font-weight: 800;
  border-width: 0;
  border-radius: 4px;
  cursor: pointer;
}

.login > button:hover {
  background-color: gainsboro;
  color: #1982FC;
}
