.chat {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  max-width: 100vw;
  background-color: white;
}

.chat__header {
  padding: 10px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid lightgray;
  background-color: #f5f5f5;
}


.chat__header {
  font-family:monospace;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}

.bot__status > p {
  font-family:monospace;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
}

.chat__name {
  color: black;
}

.chat__input {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  border-top: 1px solid lightgray;
  background-color: #f5f5f5;
}
.chat__input > form {
  flex: 1;
}

.chat__input > form > input {
  width: 98%;
  outline-width: 0;
  border: 1px solid lightgray;
  border-radius: 999px;
  padding: 5px;
}

.chat__input > send__button {
  flex: 1;
}


.chat__messages {
  flex: 1;
  overflow: scroll;
}

.chat__loading {
  display: flex;
  justify-content: center;
}

.wakeup {
  text-decoration: underline;
  cursor: pointer;
}

.chat__more_button {
  display: flex;
  height: 25px;
  align-items: center;
  padding: 10px;
  margin: 10px;
  border-radius: 4px;
  background-color: #1982FC;
  color: white;
  font-weight: 800;
  border-width: 0;
  border-radius: 4px;
  cursor: pointer;
}

.chat__more_button:hover {
  background-color: gainsboro;
  color: #1982FC;
}
.chat__messages::-webkit-scrollbar {
  display: none;
}
