.sidebar {
  flex: 0.35;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  border-right: 1px solid lightgreen;
}

.sidebar__header {
  display: flex;
  align-items: center;
  padding: 10px;
  height: 50px;
}

.sidebar__avatar {
  cursor: pointer;
  margin: 10px;
}

.sidebar__input {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: #e1e1e1;
  color: gray;
  border-radius: 5px;
}

.sidebar__input > input {
  border: none;
  background: transparent;
  outline-width: 0;
}

.sidebar__chats {
  overflow: scroll;
  overflow-x: hidden;
}

.sidebar__chats::-webkit-scrollbar {
  width: 20px;
}

.sidebar__chats::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.sidebar__chats::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 6px solid rgba(0, 0, 0, 0.18);
  border-left: 0;
  border-right: 0;
  background-color: #bebebe;
}
